<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-white d-inline-block mb-0">정산 관리</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">정산 목록</h3>
            <div class="float-right">
              <base-button type="danger" block @click="showLayer = true">등록</base-button>
            </div>
          </template>
          <div>

          </div>
        </card>
      </div>
    </div>

    <customer-register-layer
      :show-layer="showLayer1"
      @close="showLayer1 = false">
    </customer-register-layer>

    <customer-profile-key-register-layer-layer
      :show-layer="showLayer2"
      @close="showLayer2 = false">
    </customer-profile-key-register-layer-layer>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import commPaginationMixin from '@/shared/mixins/commPaginationMixin'
import { ADMIN_API_PREFIX } from '@/shared/util/const'
// import CustomerRegisterLayer from './CustomerRegisterLayer'
// import CustomerProfileKeyRegisterLayer from './CustomerProfileKeyRegisterLayer'

export default {
  mixins: [
    commPaginationMixin
  ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      showLayer1: false,
      showLayer2: false,
      testList: [],
      selectOptions: [
        {
          label: '사용자명',
          value: 'name'
        },
        {
          label: '부서',
          value: 'department'
        },
        {
          label: '아이디',
          value: 'id'
        }
      ],
      selects: {
        value: 'name',
      }
    };
  },
  created() {
    this.getList()
  },
  methods: {
    /**
     * 목록 조회
     */
    getList(page) {
      this.$axios.get(`${ADMIN_API_PREFIX}/test`, {
        params: {
          page: page ? page : this.pagination.currentPage,
          rows: this.pagination.perPage
        }
      }).then((res) => {
        this.testList = res.data // 목록정보 세팅
        this.pagination.total = 51 // 전체페이지수 세팅
      }).catch((error) => {
        console.log(error)
      })
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
